<template>
  <main>
    <content-page-header>
      <div class="hdg-tab">
        <common-page-title title="新規会員登録" />
        <tab-nation now-page="2" />
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <div class="apply-register-panel">
          <div class="temp-registration">
            <section>
              <common-block-title
                title="仮登録ありがとうございます"
                sub-title=""
                class-list="ja"
              />
              <p class="thanks-message">
                ジッセン!DXにご登録いただきありがとうございます。<br />
                確認リンクが記載されたメールをお送りしました。
                リンクをクリックして、登録プロセスを完了してください。
              </p>
              <p class="re-mail" v-if="state.remailState === 'notSend'">
                確認メールを再送信するには、<span
                  class="re-mail-action"
                  @click="reMail"
                  >ここを</span
                >クリックしてください
              </p>
              <p class="re-mail" v-if="state.remailState === 'sending'"></p>
              <p class="re-mail" v-if="state.remailState === 'success'">
                送信完了しました。
              </p>
            </section>
          </div>
        </div>
      </panel-body>
    </div>
  </main>
</template>

<script>
import { defineComponent, reactive, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import http from "@/utils/http";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import TabNation from "./components/tabNation.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "applySendComplete",

  components: {
    contentPageHeader,
    CommonPageTitle,
    TabNation,
    CommonBlockTitle,
    PanelBody,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      email: store.state.user.signUp.email,
      remailState: "notSend",
    });
    const isLogin = computed(() => store.state.user.isLogin);

    window.scrollTo(0, 0);

    const reMail = () => {
      state.remailState = "sending";
      http
        .post("/api/temporary-users/resend", {
          email: store.state.user.signUp.email,
        })
        .then((res) => {
          state.remailState = "success";
          console.log(res);
        })
        .catch((error) => {
          state.remailState = "notSend";
          console.log("エラー:", error.response.data.errors);
          if (error.config && error.response && error.response.status === 400) {
            state.errors = error.response.data.errors;
          }
        });
    };

    onMounted(async () => {
      if (isLogin.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (isLogin.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      state,
      reMail,
    };
  },
});
</script>

<style lang="scss" scoped>
.hdg-tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(lg) {
    flex-direction: column;
    align-items: flex-start;
  }
  @include mq(md) {
    display: block;
  }
}

.content-body {
  position: relative;
  margin-top: -221px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;
  @include mq(md) {
    margin-top: -590px;
  }
  @include mq(sm) {
    margin-top: -100px;
    margin-left: 20px;
  }
}
.panel-body {
  margin-bottom: 50px;
  padding: 65px;
  background-color: $white;
  box-shadow: 0px 0px 60px rgba(72, 159, 157, 0.1);
  backdrop-filter: blur(10px);
  @include mq(sm) {
    padding: 60px 30px;
  }
}
.thanks-message {
  margin-top: 40px;
}
.re-mail {
  margin-top: 80px;
  .re-mail-action {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>